import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Singleportfolio = (props) => {
    const portfolioRef = useRef(null);

  
    return (
        <div ref={portfolioRef} className="col-lg-4 col-sm-6 mt-4">
            <div className="portfolio-item">
                <Link
                    className="portfolio-link"
                    to="/"
                    data-toggle="modal"
                    href="#portfolioModal6"
                >
                    <img
                        className="img-fluid"
                        src={props.image}
                        style={{ height: "175px", width: "330px" }}
                        alt="error"
                    />
                </Link>
                <div
                    className="portfolio-caption"
                    style={{ width: "330px" }}
                >
                    <div className="portfolio-caption-heading">
                        {" "}
                        {props.title}{" "}
                    </div>
                    <div className="portfolio-caption-subheading text-muted">
                        {" "}
                        {props.subtitle}{" "}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Singleportfolio;
