import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Singleservice = (props) => {
    const serviceRef = useRef(null);
   
  return (
    <div ref={serviceRef} className="col-md-4">
      <span className="fa-stack fa-4x">
        {/* <i className="fas fa-circle fa-stack-2x text-primary" /> */}
        <i className={`fas ${props.icon} fa-stack-1x  `}/>
      </span>
      <h4 className="my-3">{props.title}</h4>
      <p className="text-muted">{props.description}</p>
    </div>
  );
};

export default Singleservice;
