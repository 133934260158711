import React from "react";
import Singleservice from "./SingleService";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import {useRef} from "react";
import {useEffect} from "react";
gsap.registerPlugin(ScrollTrigger, TextPlugin);

const services = [
  {
    title: "Trusted Professional",
    description:
      "Every tutor is experienced, friendly, manually screened, and thoroughly vetted for highest quality.",
    icon: "fa-user-tie",
  },
  {
    title: "Learn at Your Pace",
    description:
      "Book online in about 60 seconds and plan your private lessons according to your own schedule.",
    icon: "fa-calendar-alt",
  },
  {
    title: "Safe and Secure",
    description:
      "Trusted by 100s of happy parents, professionals and students with more than 90% success rate.",
    icon: "fa-shield-alt",
  },
];

const Services = () => {
    useEffect(()=>{
       gsap.defaults({ease: "none"});
        const tl = gsap.timeline({repeat:-1, repeatDelay:1,yoyo:true});
        tl.to(".section-subheading1", {duration: 4,text:" COGNITIVE CASTLE helps you connect with qualified tutors all over the world to master the subjects, skills and exams that matter to you"})

    },[])
  return (
    <React.Fragment>
      <section className="page-section" id="services">
        <div className="container">
          <div className="text-center">
          <h2 className="section-heading text-uppercase" >Services</h2>
           <h3 className="section-subheading1" style={{ fontSize: "1.0em", marginTop: 0, marginBottom: "1rem", display: "block", height:'1.5rem' }}></h3>
          </div>
          <div className="row text-center">
            {services.map((service, index) => (
              <Singleservice {...service} key={index} />
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Services;
