import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const MessageSchema = Yup.object().shape({
    
    name: Yup.string()
        .min(4, "must be more than 4 character ")
        .required("required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required("Enter your contact number"),
    message: Yup.string()
        .required("Please enter your message")
})

export const LoginSchema= Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
        
    password: Yup.string()
        .min(5, "must be at least 5 character")
        .required("Password is required"),
})

export const RegisterSchema =Yup.object().shape({
    name: Yup.string()
        .min(4, "must be more than 4 character ")
        .required("required"),
    lastName: Yup.string()
        .min(4, "must be more than 4 character ")
        .required("required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
        paypal: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
    highest_qualification:Yup.string()
   .required("Enter a valid qualification"),
   institute:Yup.string()
   .required("Enter a valid institute"),
   subject  :Yup.string()
   .required("Enter a valid subject"),  
   experience  :Yup.string()
   .required("Enter a valid experience"),
   company  :Yup.string()
   .required("Enter a valid company"),
   country :Yup.string()
   .required("Enter a valid country"),
   salary :Yup.string()
   .required("Enter a valid Salary"),
   dob   :Yup.string()
   .required("Enter a valid dob"),
   gender  :Yup.string()
   .required("Enter a valid gender"),
   phone :Yup.string()
   .required("Enter a valid Phone number"),
    
})

export const StudentRegisterSchema =Yup.object().shape({
    name: Yup.string()
        .min(4, "must be more than 4 character ")
        .required("required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
   dob   :Yup.string()
   .required("Enter a valid dob"),
   gender  :Yup.string()
   .required("Enter a valid gender"),
   studentMobileNumber :Yup.string()
   .required("Enter a valid phone number"),
   fatherName:Yup.string()
   .min(4, "must be more than 4 character ")
   .required("required"),
   fatherMobileNumber:Yup.string()
   .required("Enter a valid phone number"),
   year:Yup.string()
   .required("Enter a valid year"),
    
})

export const VolunterRegisterSchema =Yup.object().shape({
    name: Yup.string()
        .min(4, "must be more than 4 character ")
        .required("required"),
    lastName: Yup.string()
        .min(4, "must be more than 4 character ")
        .required("required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
        paypal: Yup.string()
        .email("Invalid email address")
        .required("Enter a valid email address"),
    highest_qualification:Yup.string()
   .required("Enter a valid qualification"),
   institute:Yup.string()
   .required("Enter a valid institute"),
   subject  :Yup.string()
   .required("Enter a valid subject"),  
   experience  :Yup.string()
   .required("Enter a valid experience"),
   company  :Yup.string()
   .required("Enter a valid company"),
   country :Yup.string()
   .required("Enter a valid country"),
   
   dob   :Yup.string()
   .required("Enter a valid dob"),
   gender  :Yup.string()
   .required("Enter a valid gender"),
   phone :Yup.string()
   .required("Enter a valid year"),
    
})