import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { Formik, Form, Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import { RegisterSchema } from "../../../../utils/ValidationSchema";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Breadcrumb from "./Breadcrumb";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(3),
      width: "99%",
      //height: '80ch',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
  },
  register: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "80%",
      marginLeft: "10%",
    },
  },
  paper: {
    width: "80%",
    marginTop: "2px",
    borderRadius: "15px",
  },
  profile: {
    width: "auto",
    justify: "center",
    marginLeft: "9%",
  },
}));

const SimplePaper = () => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formdata, { resetForm }) => {
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://new-server.cognitive-castle.tech/api/admin/facultyReg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        },
      );

      const data = await response.json();

      if (response.ok) {
        alert("data sent successfully");
        resetForm();
      } else {
      }
    } catch (error) {
      alert("Data is not sent");
    }
    ///alert(JSON.stringify(data, null, 2));
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name: "",
          lastName: "",
          email: "",
          paypal: "",
          highest_qualification: "",
          institute: "",
          subject: "",
          experience: "",
          company: "",
          country: "",
          salary: "",
          dob: "",
          gender: "",
          phone: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={RegisterSchema}
      >
        <React.Fragment>
          <div className={classes.root}>
            <Paper elevation={4}>
              <Breadcrumb />
              <Container maxWidth="md">
                <Grid item xs={12}>
                  <Paper
                    className={classes.paper}
                    style={{
                      marginBottom: "20px",
                      marginLeft: "8%",
                    }}
                  >
                    <h1 className="profile_top pt-3">
                      Earn additionally
                                        </h1>
                    <h1 className="profile">
                      money per month with Cognitive
                      Castle.
                                        </h1>
                    <h1 className="profile">
                      Create your tutor profile!
                                        </h1>
                    <div className="form_input">
                      <Form className={classes.register}>
                        <Field
                          name="name"
                          variant="outlined"
                          type="text"
                          label="Enter your name"
                          component={TextField}
                        />
                        <Field
                          name="lastName"
                          variant="outlined"
                          type="text"
                          label="Enter your last name"
                          component={TextField}
                        />

                        <Field
                          name="email"
                          variant="outlined"
                          type="email"
                          label="Enter your valid email"
                          component={TextField}
                        />
                        <Field
                          name="paypal"
                          variant="outlined"
                          type="email"
                          label="Enter your paypal email"
                          component={TextField}
                        />
                        <Field
                          name="dob"
                          variant="outlined"
                          type="date"
                          label=" ___________ (Your DOB)"
                          component={TextField}
                        />
                        <Field
                          name="gender"
                          variant="outlined"
                          component={Select}
                          label="Select Gender"
                          type="option"
                          style={{
                            width: "80%",
                            marginLeft: "10%",
                          }} // Set the desired width value
                          inputProps={{
                            name: "gender",
                          }}
                        >
                          <InputLabel>
                            Select Gender
                                                    </InputLabel>
                          <MenuItem value="male">
                            Male
                                                    </MenuItem>
                          <MenuItem value="female">
                            Female
                                                    </MenuItem>
                          <MenuItem value="other">
                            Other
                                                    </MenuItem>
                        </Field>
                        <Field
                          name="highest_qualification"
                          variant="outlined"
                          type="text"
                          label="Enter your Highest Qualification"
                          component={TextField}
                        />
                        <Field
                          name="institute"
                          variant="outlined"
                          type="text"
                          label="Enter your Last Institute Name"
                          component={TextField}
                        />
                        <Field
                          name="subject"
                          variant="outlined"
                          type="text"
                          label="Enter the subjects you can teach"
                          component={TextField}
                        />
                        <Field
                          name="experience"
                          variant="outlined"
                          type="text"
                          label="Enter the year of experiance you have"
                          component={TextField}
                        />
                        <Field
                          name="company"
                          variant="outlined"
                          type="text"
                          label="Enter the last attended company"
                          component={TextField}
                        />
                        <Field
                          name="country"
                          variant="outlined"
                          type="text"
                          label="Enter your country"
                          component={TextField}
                        />

                        <Field
                          name="phone"
                          variant="outlined"
                          type="text"
                          label="Enter your phone number with country code"
                          component={TextField}
                        />

                        <Field
                          name="salary"
                          variant="outlined"
                          type="text"
                          label="Enter your expected salary per month in usd"
                          component={TextField}
                        />

                        <div
                          className={classes.profile}
                        >
                          <Button
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="1rem" />
                              ) : null
                            }
                            type="submit"
                            variant="contained"
                            color="primary"
                            className=" m-2"
                            disabled={isSubmitting}
                          >
                            Create a profile
                                                    </Button>
                        </div>
                      </Form>
                    </div>
                  </Paper>
                </Grid>
              </Container>
            </Paper>
          </div>
        </React.Fragment>
      </Formik>
    </React.Fragment>
  );
};

export default SimplePaper;
