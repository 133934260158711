import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Team = () => {
    const textRef=useRef(null);
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const service = textRef.current;
        gsap.defaults({ease: "none"});
        const tl = gsap.timeline({repeat:-1, repeatDelay:1,yoyo:true});
        tl.to(service, {duration: 4,text:" Get fully vetted home tutors who're specifically experienced in teaching, mentoring and inspiring kids like yours."})

        // Your GSAP animations here
    }, []);

    return (
        <React.Fragment>
            <section className="page-section bg-light" id="team">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Our Amazing Team</h2>
                        <h3 className="section-subheading text-muted">No need to struggle. Get an experienced tutor.</h3>
                    </div>
                    <div className="row" style={{ height:'2.5rem'}}>
                        <div  className="col-lg-8 mx-auto text-center">
                            <p ref={textRef} className="large text-muted"></p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Team;
