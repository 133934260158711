import React, { Component } from 'react'
import image from "../assets/front.jpeg"

// Re-usable componets
import Services from "../Common/Services"
import Portfolio from "../Common/Portfolio"
import Team from '../Common/Team'
import Contact from '../Common/Contact'
import Footer from '../Common/Footer'


class Home extends Component {


    render() {
    
        return (
            <React.Fragment>
               
        <img src={image} style={{width:'100%',height:'750px'}}/>
                    <Services /> 
                    <Portfolio />
                    <Team />
                    <Contact />
                    <Footer/>
                
            </React.Fragment>
        )
    }
}

export default Home
