import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import { Formik, Form, Field } from "formik";
import { TextField, Select } from "formik-material-ui";
import { StudentRegisterSchema } from "../../../../utils/ValidationSchema";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Breadcrumb from "./Breadcrumb";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(3),
      width: "99%",
      //height: '80ch',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
  },
  register: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "80%",
      marginLeft: "10%",
    },
  },
  paper: {
    width: "80%",
    marginTop: "2px",
    borderRadius: "15px",
  },
  profile: {
    width: "auto",
    justify: "center",
    marginLeft: "9%",
  },
}));

const StudentRegister = () => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (formdata, { resetForm }) => {
    console.log("In handle submit section");
    setIsSubmitting(true);

    try {
      const response = await fetch(
        "https://new-server.cognitive-castle.tech/api/admin/addStudent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        },
      );

      const data = await response.json();

      if (response.ok) {
        alert("data sent successfully");
        resetForm();
      } else {
        if (data && data.email) {
          // Assuming the error object returned contains a specific error message
          alert(data.email);
        } else {
          alert("Data is not sent. Please try again."); // Generic error message
        }
        console.log("Error data:", data);
      }
    } catch (error) {
      alert("An error occurred while sending data. Please try again.");
      console.error("Caught an exception:", error);
      //console.log("the data is",data);
    }
    ///alert(JSON.stringify(data, null, 2));
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name: "",
          email: "",
          dob: "",
          gender: "",
          studentMobileNumber: "",
          fatherName: "",
          fatherMobileNumber: "",
          year: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={StudentRegisterSchema}
      >
        <React.Fragment>
          <div className={classes.root}>
            <Paper elevation={4}>
              <Breadcrumb />
              <Container maxWidth="md">
                <Grid item xs={12}>
                  <Paper
                    className={classes.paper}
                    style={{
                      marginBottom: "20px",
                      marginLeft: "8%",
                    }}
                  >
                    <h1
                      className="profile"
                      style={{ paddingTop: "15px" }}
                    >
                      Create your Student profile!
                                        </h1>
                    <div className="form_input">
                      <Form className={classes.register}>
                        <Field
                          name="name"
                          variant="outlined"
                          type="text"
                          label="Enter your name"
                          component={TextField}
                        />

                        <Field
                          name="email"
                          variant="outlined"
                          type="email"
                          label="Enter your valid email"
                          component={TextField}
                        />

                        <Field
                          name="fatherName"
                          variant="outlined"
                          type="text"
                          label="Enter your Guardian/parent name"
                          component={TextField}
                        />
                        <Field
                          name="fatherMobileNumber"
                          variant="outlined"
                          type="text"
                          label="Enter your Guardian/parent number"
                          component={TextField}
                        />

                        <Field
                          name="dob"
                          variant="outlined"
                          type="date"
                          label=" ___________ (Your DOB)"
                          component={TextField}
                        />
                        <h6
                          style={{
                            marginLeft: "80px",
                            marginTop: "7px",
                          }}
                        >
                          Select Gender
                                                </h6>

                        <Field
                          name="gender"
                          variant="outlined"
                          component={Select}
                          label="Select Gender"
                          type="MenuItem"
                          style={{
                            width: "80%",
                            marginLeft: "10%",
                          }} // Set the desired width value
                          inputProps={{
                            name: "gender",
                          }}
                        >
                          <InputLabel>
                            Select Gender
                                                    </InputLabel>
                          <MenuItem value="male">
                            Male
                                                    </MenuItem>
                          <MenuItem value="female">
                            Female
                                                    </MenuItem>
                          <MenuItem value="other">
                            Other
                                                    </MenuItem>
                        </Field>
                        <h6
                          style={{
                            marginLeft: "80px",
                            marginTop: "7px",
                          }}
                        >
                          Select Year
                                                </h6>
                        <Field
                          name="year"
                          variant="outlined"
                          component={Select}
                          label="Select year"
                          type="MenuItem"
                          style={{
                            width: "80%",
                            marginLeft: "10%",
                          }} // Set the desired width value
                          inputProps={{
                            name: "year",
                          }}
                        >
                          <InputLabel>
                            Select Year
                                                    </InputLabel>
                          <MenuItem value="1">
                            1
                                                    </MenuItem>
                          <MenuItem value="2">
                            2
                                                    </MenuItem>
                          <MenuItem value="3">
                            3
                                                    </MenuItem>
                          <MenuItem value="4">
                            4
                                                    </MenuItem>
                          <MenuItem value="5">
                            5
                                                    </MenuItem>
                          <MenuItem value="6">
                            6
                                                    </MenuItem>
                          <MenuItem value="7">
                            7
                                                    </MenuItem>
                          <MenuItem value="8">
                            8
                                                    </MenuItem>
                          <MenuItem value="9">
                            9
                                                    </MenuItem>
                          <MenuItem value="10">
                            10
                                                    </MenuItem>
                          <MenuItem value="11">
                            11
                                                    </MenuItem>
                          <MenuItem value="12">
                            12
                                                    </MenuItem>
                          <MenuItem value="12+">
                            12+
                                                    </MenuItem>
                        </Field>

                        <Field
                          name="studentMobileNumber"
                          variant="outlined"
                          type="text"
                          label="Enter your phone number with country code"
                          component={TextField}
                        />

                        <div
                          className={classes.profile}
                        >
                          <Button
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="1rem" />
                              ) : null
                            }
                            type="submit"
                            variant="contained"
                            color="primary"
                            className=" m-2"
                            disabled={isSubmitting}
                          >
                            Create a profile
                                                    </Button>
                        </div>
                      </Form>
                    </div>
                  </Paper>
                </Grid>
              </Container>
            </Paper>
          </div>
        </React.Fragment>
      </Formik>
    </React.Fragment>
  );
};

export default StudentRegister;
