import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaTimes } from 'react-icons/fa';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from 'react';
import { useEffect } from 'react';

gsap.registerPlugin(ScrollTrigger);

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.number().required('Phone number is required'),
  message: Yup.string().required('Message is required'),
});

// Popup component
const Popup = ({ message, onClose }) => {
  // Close popup after 5 seconds
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'green',
        color: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        zIndex: 9999,
      }}
    >
      <p>{message}</p>
      <button
        onClick={onClose}
        style={{
          background: 'none',
          border: 'none',
          color: 'red',
          cursor: 'pointer',
          marginTop: '10px',
          fontSize: '20px',
        }}
      >
        <FaTimes />
      </button>
    </div>
  );
};

// Contact component
const Contact = () => {
  // State for showing/hiding the popup
  const [showPopup, setShowPopup] = useState(false);
  // State for the popup message
  const [popupMessage, setPopupMessage] = useState('');
  const formContainerRef = useRef(null);
  // Function to handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch('https://new-server.cognitive-castle.tech/api/admin/uploadContact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setPopupMessage('Form submitted successfully!');
        setShowPopup(true);
      } else {
        setPopupMessage('Failed to submit form!');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setPopupMessage('Failed to submit form!');
      setShowPopup(true);
    } finally {
      // Clear form fields
      values.phone = '';
      values.message = '';
      values.name = '';
      values.email = '';
      setSubmitting(false);
    }
  };

  const animateFormOnScroll = () => {
    gsap.from('.form-group', {
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.2,
      scrollTrigger: {
        trigger: '#contact',
        start: 'top center',
        once:false,
      },
    });

   
  };

  // Call the animateFormOnScroll function inside a useEffect hook to trigger the animation when the component mounts
  useEffect(() => {
    animateFormOnScroll();
  }, []);


  return (
    <section className="page-section" id="contact">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Contact Us</h2>
        </div>
        <div
        className='form-container'
        ref={formContainerRef}
          style={{
            width: '75%',
            background:
              'linear-gradient(#212121, #212121) padding-box, linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box',
            border: '2px solid transparent',
            padding: '32px 24px',
            fontSize: '14px',
            fontFamily: 'inherit',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
            margin: '0 auto',
          }}
        >
          <Formik

            initialValues={{
              name: '',
              email: '',
              phone: '',
              message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center' }}>
                  <div className="form-group" style={{ flex: 1, marginRight: '1rem' }}>
                    <label htmlFor="name" style={{ display: 'block', marginBottom: '5px', color: '#717171', fontWeight: '600', fontSize: '12px' }}>Name</label>
                    <Field type="text" name="name" style={{ width: '100%', padding: '12px 16px', borderRadius: '8px', color: '#fff', fontFamily: 'inherit', backgroundColor: 'transparent', border: '1px solid #414141' }} />
                    <ErrorMessage name="name" component="div" className="error" style={{ color: 'red' }} />
                  </div>
                  <div className="form-group" style={{ flex: 1, marginLeft: '1rem' }}>
                    <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', color: '#717171', fontWeight: '600', fontSize: '12px' }}>Email</label>
                    <Field type="email" name="email" style={{ width: '100%', padding: '12px 16px', borderRadius: '8px', color: '#fff', fontFamily: 'inherit', backgroundColor: 'transparent', border: '1px solid #414141' }} />
                    <ErrorMessage name="email" component="div" className="error" style={{ color: 'red' }} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="phone" style={{ display: 'block', marginBottom: '5px', color: '#717171', fontWeight: '600', fontSize: '12px' }}>Phone Number</label>
                  <Field type="tel" name="phone" style={{ width: '100%', padding: '12px 16px', borderRadius: '8px', color: '#fff', fontFamily: 'inherit', backgroundColor: 'transparent', border: '1px solid #414141' }} />
                  <ErrorMessage name="phone" component="div" className="error" style={{ color: 'red' }} />
                </div>
                <div className="form-group">
                  <label htmlFor="message" style={{ display: 'block', marginBottom: '5px', color: '#717171', fontWeight: '600', fontSize: '12px' }}>How Can We Help You?</label>
                  <Field as="textarea" name="message" rows="4" style={{ width: '100%', padding: '12px 16px', borderRadius: '8px', resize: 'none', color: '#fff', height: '96px', border: '1px solid #414141', backgroundColor: 'transparent', fontFamily: 'inherit' }} />
                  <ErrorMessage name="message" component="div" className="error" style={{ color: 'red' }} />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    alignSelf: 'flex-start',
                    fontFamily: 'inherit',
                    color: '#fff',
                    fontWeight: '600',
                    width: '40%',
                    background: isSubmitting ? '#ccc' : '#5cb85c',
                    border: '1px solid #414141',
                    padding: '12px 16px',
                    fontSize: 'inherit',
                    gap: '8px',
                    marginTop: '8px',
                    cursor: 'pointer',
                    borderRadius: '6px',
                  }}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </Form>
            )}
          </Formik>
          {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
        </div>
      </div>
    </section>
  );
};

export default Contact;
