import React from 'react';
import {Route} from 'react-router-dom'
import Pagewrapper from './components/website/PageWrapper';
import Home from './components/website/Pages/Home';
import Login from './components/website/Pages/admin/Login'
import Register from './components/website/Pages/admin/Register'
import AppBar from './components/website/Pages/admin/AppBar'
import TestingReg from './components/website/Pages/admin/TestingReg'
import StudentLogin from './components/website/Pages/admin/StudentLogin'
import StudentRegister from './components/website/Pages/admin/StudentRegister'
import VolunteerRegister from './components/website/Pages/admin/VolunteerRegister'
import VolunteerLogin from './components/website/Pages/admin/VolunteerLogin'


function App() {
  return (
    <React.Fragment>
   
  

      <Route 
        exact={true} 
        path="/" 
        render={props=>(
        <Pagewrapper>
          <Home {...props} />
        </Pagewrapper>)} 
      />

      <Route 
        exact={true} 
        path="/login" 
        render={props=>(
        <AppBar>
          <Login {...props} />
        </AppBar>)} 
      />
      <Route 
        exact={true} 
        path="/Studentlogin" 
        render={props=>(
        <AppBar>
          <StudentLogin {...props} />
        </AppBar>)} 
      />

      <Route 
        exact={true} 
        path="/register" 
        render={props=>(
        <AppBar>
          <Register {...props} />
        </AppBar>)} 
      />
      
      <Route 
        exact={true} 
        path="/studentregister" 
        render={props=>(
        <AppBar>
          <StudentRegister {...props} />
        </AppBar>)} 
      />
     <Route 
        exact={true} 
        path="/volunteeregister" 
        render={props=>(
        <AppBar>
          <VolunteerRegister {...props} />
        </AppBar>)} 
      />

       <Route 
        exact={true} 
        path="/volunteerlogin" 
        render={props=>(
        <AppBar>
          <VolunteerLogin {...props} />
        </AppBar>)} 
      />

      <Route 
        exact={true} 
        path="/reg" 
        render={props=>(
        <AppBar>
          <TestingReg {...props} />
        </AppBar>)} 
      />
     
    </React.Fragment>
  );
}

export default App;
