import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Pagewrapper = ({ children }) => {

  useEffect(() => {
    gsap.fromTo(
      ".navbar",
      { opacity: 0, y: -50 },
      { opacity: 1, y: 0, duration: 0.5, delay: 0.5, ease: "power3.out" }
    );
  
    gsap.fromTo(
      ".nav-item",
      { opacity: 0, x: -50 },
      { opacity: 1, x: 0, duration: 0.2, stagger: 0.1, delay: 1, ease: "power2.out" }
    );
     gsap.from('.section-heading', {
      opacity: 0,
      x: -300,
      duration: 2,
      stagger: 0.2,
      scrollTrigger: {
        trigger: '.page-section',
        start: "top center",
        once:false,
        
      },
    });
  }, []);
  

  return (
    <React.Fragment>
   <nav
        className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
        id="mainNav"
      >
        <div className="container" style={{width:'150%'}}>
          <a className="navbar-brand js-scroll-trigger" href="/">
            Cognitive Castle
          </a>
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu <i className="fas fa-bars ml-1" />
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarResponsive"
          >
            <ul className="navbar-nav text- ml-auto">
              <li
                className="nav-item"
                style={{ fontSize: "10px" }}
              >
                <a
                  className="nav-link js-scroll-trigger"
                  style={{
                    color: "#B2B377",
                    fontWeight: "bold",
                  }}
                  href="#services"
                >
                  Services
                </a>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "10px" }}
              >
                <a
                  className="nav-link js-scroll-trigger"
                  style={{
                    color: "#B2B377",
                    fontWeight: "bold",
                  }}
                  href="#portfolio"
                >
                  Portfolio
                </a>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "10px" }}
              >
                <a
                  className="nav-link js-scroll-trigger"
                  style={{
                    color: "#B2B377",
                    fontWeight: "bold",
                  }}
                  href="#team"
                >
                  Team
                </a>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "10px" }}
              >
                <a
                  className="nav-link js-scroll-trigger"
                  style={{
                    color: "#B2B377",
                    fontWeight: "bold",
                  }}
                  href="#contact"
                >
                  Contact
                </a>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "8px" }}
              >
                <Link
                  className="nav-link js-scroll-trigger become-a-tutor"
                  to="/Studentlogin"
                >
                  Register
                </Link>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "8px" }}
              >
                <Link
                  className="nav-link js-scroll-trigger become-a-tutor"
                  to="/login"
                >
                  Tutoring
                </Link>
              </li>
              <li
                className="nav-item"
                style={{ fontSize: "8px" }}
              >
                <Link
                  className="nav-link js-scroll-trigger become-a-tutor"
                  to="/volunteerlogin"
                >
                  Become a Volunteer
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {children}
    </React.Fragment>
  );
};

export default Pagewrapper;
