import React from 'react'
// import {Link} from 'react-router-dom'

function Footer(props) {
    return (
        <React.Fragment>
           <footer className="footer py-4">
                <div className="container">
                    <div className="row align-items-center" style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <div className="col-lg-4 text-lg-left">Copyright © Cognitive-Castle 2024</div>
                    
                    <div className="col-lg-4 text-lg-right">
                        <a className="mr-3" href="#!">Privacy Policy</a>
                        <a href="#!">Terms and Conditions</a>
                    </div>
                    </div>
                </div>
            </footer>
            
        </React.Fragment>
    )
}

export default Footer